var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"course-play-page"},[_c('el-breadcrumb',{staticClass:"bread-crumb",attrs:{"separator":"/"}},[_c('el-breadcrumb-item',[_c('span',{on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._v("首页")])]),_c('el-breadcrumb-item',[_c('span',{on:{"click":_vm.toCourseList}},[_vm._v(_vm._s(_vm.courseName))])]),_c('el-breadcrumb-item',[_c('span',{staticStyle:{"color":"#333"}},[_vm._v(_vm._s(_vm.videoInfo.video_name))])])],1),_c('div',{staticClass:"player-box"},[_c('div',{staticClass:"player-video",style:({ width: _vm.$route.query.fid ? '100%' : '' }),attrs:{"id":"dplayer"}},[(JSON.stringify(_vm.videoInfo) !== '{}')?_c('VideoPlayer',{ref:"videoEle",attrs:{"videoInfo":_vm.videoInfo}}):_vm._e()],1),(!_vm.$route.query.fid)?_c('div',{staticClass:"player-chapter"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!this.$route.query.fid),expression:"!this.$route.query.fid"}]},[_vm._v("课程目录")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(this.$route.query.fid),expression:"this.$route.query.fid"}]},[_vm._v("免费课程")]),_c('ul',_vm._l((_vm.videoList),function(video){return _c('li',{key:video.id,on:{"click":function($event){return _vm.changeVideo(video.id)}}},[_c('span',{staticClass:"course-title"},[_vm._v(" "+_vm._s(video.video_name)+" ")]),_c('span',{staticClass:"course-name"},[_vm._v(" "+_vm._s(video.title)+" ")]),(_vm.videoInfo.click_video_id != video.id)?_c('i',{staticClass:"course-status",style:({
              backgroundPositionY:
                video.finish_see === 1
                  ? '-20%'
                  : video.finish_see === 0
                  ? '120%'
                  : '50%',
            })}):_vm._e(),(_vm.videoInfo.click_video_id == video.id)?_c('i',{staticClass:"course-playing"},[_c('img',{attrs:{"src":require("../assets/images/playing.gif"),"alt":""}})]):_vm._e()])}),0)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }