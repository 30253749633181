<template>
  <div id="dplayer"></div>
</template>

<script>
import DPlayer from "dplayer";
export default {
  name: "VideoPlayer",
  props: {
    videoInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      player: null,
      currentTime: 0,
      videoOptions: {
        container: document.getElementById("dplayer"),
        autoplay: true,
        lang: "zh-cn",
        hotkey: true,
        preload: "auto",
        volume: 0.3,
        replay: true,
        video: {},
        contextmenu: [],
      },
    };
  },
  mounted() {
    console.log(this.videoInfo);
    const _this = this;
    this.videoOptions.video.url = this.videoInfo.video_url;
    this.player = new DPlayer(this.videoOptions);
    if (this.videoInfo.last_time != 0) {
      this.player.seek(Number(this.videoInfo.last_time));
    }
    this.player.on("timeupdate", function () {
      _this.currentTime = parseInt(_this.player.video.currentTime);
      // if (_this.currentTime >= _this.videoInfo.video_time) {
      // _this.$message.success("该视频学习完成");
      // _this.player.seek(0);
      // _this.player.pause();
      // }
    });
    this.player.on("play", function () {
      if (_this.currentTime >= _this.videoInfo.video_time) {
        // _this.$message.success("该视频学习完成");
        _this.player.seek(0);
        // _this.player.pause();
      }
      if (_this.videoInfo.video_time == _this.videoInfo.last_time) {
        _this.player.seek(0);
      }
    });
    this.player.on("ended", function (param) {
      if (_this.videoInfo.see_finish != 1) {
        _this.addStudyTime();
      }
      _this.updateTime();
    });
    window.addEventListener("unload", this.closePage);
  },
  methods: {
    updateTime() {
      let fd = new FormData();

      fd.append(
        "video_id",
        this.videoInfo.click_video_id
          ? this.videoInfo.click_video_id
          : this.videoInfo.id
      );
      fd.append("last_time", this.currentTime);
      fd.append("flag", this.videoInfo.click_video_id ? 1 : 2);
      this.axios.post("/coursepc/index/last_video_time", fd).then((res) => {
        console.log(res);
      });
    },
    addStudyTime() {
      let addTime =
        parseInt(this.currentTime) - parseInt(this.videoInfo.last_time);
      if (addTime == 0) {
        return false;
      }
      let fd = new FormData();
      fd.append("s_time", addTime);
      this.axios.post("/coursepc/index/add_study_time", fd).then((res) => {
        console.log(res);
      });
    },
    closePage() {
      if (this.videoInfo.see_finish != 1) {
        this.addStudyTime();
      }
      this.updateTime();
    },
  },
  beforeDestroy() {
    if (this.player) {
      if (this.videoInfo.see_finish != 1) {
        this.addStudyTime();
      }
      this.updateTime();
      this.player.destroy();
    }
  },
};
</script>
<style lang="scss">
.video-modal-diy {
  font-size: 30px;
  text-align: center;
}
</style>
